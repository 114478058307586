<template>
  <transition name="appheight" @enter="enterH" @afterLeave="afterLeaveH">
    <!-- <div v-if="forceTransition" style="height: 0px" /> -->
    <!-- TODO the url bar and such on iOS are REALLY annoying, I want to make a window that prompts the user to hide them if a discrepency is detected -->
    <v-app
      style="
        background-color: #f1f1f1 !important;
        max-width: 100vw !important;
        min-height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
      "
      ref="app"
      v-show="!forceTransition"
    >
      <v-app-bar
        app
        fixed
        color="#6AAB96"
        dark
        shrink-on-scroll
        elevate-on-scroll
        prominent
        src="https://picsum.photos/1920/1080?random"
        fade-img-on-scroll
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(106,171,150,.7), rgba(31,55,48,.7)"
          />
        </template>

        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

        <v-app-bar-title
          class="text-no-wrap no-title-shrink"
          :style="navStyle"
          @click="goHome"
          ><span @click="goHome"
            ><v-icon
              :style="canNav ? '' : 'opacity: 0;'"
              class="mr-2 pb-1"
              size="28"
              >mdi-home</v-icon
            >Penny For Your Thoughts</span
          ></v-app-bar-title
        >

        <v-spacer></v-spacer>

        <!-- <v-btn icon @click="goLogin">
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn> -->

        <!-- <template v-slot:extension>
          <v-tabs align-with-title>
            <v-tab>Tab 1</v-tab>
            <v-tab>Tab 2</v-tab>
            <v-tab>Tab 3</v-tab>
          </v-tabs>
        </template> -->
      </v-app-bar>

      <!-- <v-main> -->

      <v-container
        fluid
        style="
          padding-top: 140px;
          margin-bottom: 60px;
          min-height: calc(100% - 60px);
          position: relative;
        "
        class="d-flex align-stretch"
        color="grey lighten-3"
      >
        <transition
          :name="transitionName"
          @beforeLeave="beforeLeave"
          @afterEnter="afterEnter"
        >
          <router-view
            style="min-width: calc(100vw - 24px); max-width: calc(100vw - 24px)"
          />
        </transition>
      </v-container>

      <!-- </v-main> -->
      <v-footer bottom absolute color="grey lighten-1">
        <v-col class="text-left">
          {{ new Date().getFullYear() }} —
          <strong>Penny For Your Thoughts</strong>
        </v-col>
      </v-footer>
      <v-dialog v-model="loadingDialog" persistent>
        <v-card>
          <v-container fluid>
            <center>
              <h1>
                {{ loadingText }}
              </h1>
              <v-progress-linear indeterminate height="10" striped rounded />
            </center>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="errorDialog" persistent>
        <v-card>
          <v-container fluid>
            <v-alert prominent type="error">
              <template v-if="errorArray.length == 1">
                {{ errorArray[0] }}
              </template>
              <template v-else>
                The following errors have occurred: <br />
                <ul>
                  <li v-for="(item, index) in errorArray" :key="index">
                    {{ item }}
                  </li>
                </ul>
              </template>
            </v-alert>
          </v-container>
          <v-card-actions v-if="canCloseErrors">
            <v-spacer /><v-btn @click="clearErrors">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </transition>
</template>

<style>
:root {
  --transition-time: 0.25s;
  /* --transition-time: 25s; */
}

.no-title-shrink > div {
  width: auto !important;
}

.appheight-enter-active,
.appheight-leave-active {
  transition-duration: var(--transition-time);
  transition-property: height;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

.appheight-enter,
.appheight-enter-to,
.appheight-leave,
.appheight-leave-to {
  height: auto;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: var(--transition-time);
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  position: relative;
}

.slide-left-enter,
.slide-right-leave-to {
  opacity: 0;
  transform: translate(100vw, 0);
  position: absolute;
}

.slide-right-leave-to,
.slide-left-leave-to {
  position: absolute;
  top: 0;
}

.slide-left-leave-to,
.slide-right-enter {
  opacity: 0;
  transform: translate(-100vw, 0);
  position: absolute;
}
</style>

<script>
export default {
  name: "App",
  data() {
    return {
      online: navigator.onLine,
      transitionName: "slide-right",
      prevHeight: 0,
      forceTransition: false,
      windowSizeTimeout: null,
      windowTimeThresh: 250,
      windowSizeThresh: 50,
    };
  },
  methods: {
    async goHome() {
      await this.$store.dispatch("resetStore");
      if (this.canNav) {
        window.scrollTo(0, 0);
        this.$router.push("/").catch(() => {
          // don't care.
        });
      }
    },
    async goLogin() {
      await this.$store.dispatch("resetStore");
      this.$router.push("/login").catch(() => {
        // don't care.
      });
    },
    clearErrors() {
      this.$store.commit("clearErrors");
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.online = type === "online";
      this.handleOnline();
    },
    handleOnline() {
      let errorText =
        "This site requires an internet connection to function properly.";
      if (!this.online) {
        this.$store.commit("addError", {
          text: errorText,
          canClose: false,
        });
      } else {
        this.$store.commit("removeError", errorText);
      }
    },
    afterLeaveH() {
      this.forceTransition = false;
      this.$refs.app.$el.style.height = null;
    },
    enterH(el, done) {
      done();
    },
    beforeLeave() {
      this.prevHeight = getComputedStyle(this.$refs.app.$el).height;
      this.$refs.app.$el.style.height = this.prevHeight;
    },
    afterEnter() {
      let self = this;
      self.$refs.app.$el.style.height = "auto";
      const { height } = getComputedStyle(self.$refs.app.$el);
      self.$refs.app.$el.style.height = self.prevHeight;
      getComputedStyle(self.$refs.app.$el).height;
      self.forceTransition = true;
      requestAnimationFrame(() => {
        self.$refs.app.$el.style.height = height;
      });
    },
    setWindowSize() {
      let self = this;
      let width = window.innerWidth;
      let height = window.innerHeight;
      let curSize = self.$store.getters.windowSize;
      if (
        Math.max(
          Math.abs(curSize.width - width),
          Math.abs(curSize.height - height)
        ) > self.windowSizeThresh ||
        (curSize.width > 490 && width <= 490) ||
        (curSize.width <= 490 && width > 490)
      ) {
        if (self.windowSizeTimeout != null) {
          clearTimeout(self.windowSizeTimeout);
          self.windowSizeTimeout = null;
        }
        self.setSize();
      } else {
        if (self.windowSizeTimeout != null) {
          clearTimeout(self.windowSizeTimeout);
        }
        self.windowSizeTimeout = setTimeout(
          self.setSize,
          self.windowTimeThresh
        );
      }
    },
    setSize() {
      this.$store.getters.windowSize.width = window.innerWidth;
      this.$store.getters.windowSize.height = window.innerHeight;
      if (this.windowSizeTimeout) {
        clearTimeout(this.windowSizeTimeout);
        this.windowSizeTimeout = null;
      }
    },
  },
  computed: {
    canNav() {
      return this.$route.path != "/";
    },
    navStyle() {
      if (this.canNav) {
        return "cursor: pointer;";
      } else {
        return "";
      }
    },
    errorArray() {
      return this.$store.getters["errors"];
    },
    errorDialog() {
      return this.errorArray.length !== 0;
    },
    canCloseErrors() {
      return this.$store.getters["closeErrors"];
    },
    topLoading() {
      return this.$store.getters["topLoading"];
    },
    loadingDialog() {
      return this.topLoading != null;
    },
    loadingText() {
      if (this.topLoading) {
        if (this.topLoading.shouldShow) {
          return `${this.topLoading.text}...`;
        } else {
          return "Please Wait...";
        }
      } else {
        return "";
      }
    },
  },
  mounted() {
    let self = this;
    window.addEventListener("online", self.updateOnlineStatus);
    window.addEventListener("offline", self.updateOnlineStatus);
    self.$store.dispatch("clearLocalStorage");
    self.setSize();
    window.addEventListener("resize", self.setWindowSize);
    self.handleOnline();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
    window.removeEventListener("resize", this.setWindowSize);
  },
  watch: {
    $route(to, from) {
      let depths = {
        Home: 1,
        "Create Message": 2,
        FAQ: 2,
        About: 2,
        Login: 2,
      };
      let fromLevel = Object.prototype.hasOwnProperty.call(depths, from.name)
        ? depths[from.name]
        : 999;
      let toLevel = Object.prototype.hasOwnProperty.call(depths, to.name)
        ? depths[to.name]
        : 999;
      if (toLevel < fromLevel) {
        this.transitionName = "slide-right";
      } else {
        this.transitionName = "slide-left";
      }
    },
  },
};
</script>
