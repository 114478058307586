<template>
  <div>
    Please enter your email address again to confirm. All login information will
    be generated and emailed automatically, so be sure it is entered correctly.
    <v-text-field
      v-model="emailModel"
      label="Confirm Email Address"
      :rules="[(v) => v == email || 'Email Addresses must match.']"
      ref="input"
    />
  </div>
</template>

<script>
export default {
  name: "EmailConfirmStep",
  props: ["email", "emailConfirm"],
  computed: {
    emailModel: {
      get() {
        return this.emailConfirm;
      },
      set(newVal) {
        this.$emit("update:emailConfirm", newVal);
      },
    },
  },
};
</script>
