<template>
  <div>
    <v-card class="pa-3">
      <div v-if="Object.keys(savedLogins).length">
        <h2>Welcome Back!</h2>
        <br />
        For a quick log-in, please select the message for which you'd like to
        scan a new penny QR code.
        <br /><br />
        <v-row v-for="(code, description) in savedLogins" :key="description">
          <v-col>
            <v-btn @click="$router.push(`/u/${encodeURIComponent(code)}`)">
              {{ description }}
            </v-btn>
            <v-btn icon @click="removeLogin(description)">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <div v-else>
        <h2>There are no saved logins!</h2>
      </div> -->
      <v-row justify="center" class="my-5">
        <v-col cols="auto" align-self="center">
          <!-- <v-spacer /> -->
          <v-btn @click="showQRScanner = true" color="info" block>
            <v-icon left>mdi-qrcode-plus</v-icon>
            Scan a {{ Object.keys(savedLogins).length ? "New " : "" }}Login Code
          </v-btn>
          <!-- <v-spacer /> -->
        </v-col>
      </v-row>
      Scan your "Login QR" code{{
        Object.keys(savedLogins).length
          ? " (or click on one of the messages above) "
          : " "
      }}to scan a penny. The "Login QR" will be bigger and more complicated -
      see the comparison below. <br /><br />
      <v-row>
        <v-col cols="6" md="4" lg="3">
          <center>
            <h4>Penny QR</h4>
          </center>
          <v-img
            src="@/assets/pennyExample.svg"
            alt="Example Penny QR Code"
            class="ma-2"
          />
        </v-col>
        <v-col cols="6" md="4" lg="3">
          <center>
            <h4>Login QR</h4>
          </center>
          <v-img
            src="@/assets/loginExample.svg"
            alt="Example Login QR Code"
            class="ma-2"
          />
        </v-col>
      </v-row>
      <br />
      If it's lost, it can be re-printed by the message author - they can log in
      through a link sent to their email.
      <qr-scanner
        v-if="showQRScanner"
        v-model="showQRScanner"
        @scan="handleScan"
      />
    </v-card>
    <v-snackbar vertical v-model="showSnackbar" color="error" timeout="10000">
      Sorry, that doesn't appear to be a valid login QR for this website.
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import qrScanner from "@/components/qrScanner";
// const qrScanner = () => import("@/components/qrScanner.vue");

export default {
  name: "Login",
  data() {
    return {
      showSnackbar: false,
      showQRScanner: false,
      forceUpdate: 0,
    };
  },
  computed: {
    savedLogins() {
      let outValue = this.forceUpdate;
      outValue = this.$store.getters.localStore("pennyLogins");
      return outValue;
    },
  },
  methods: {
    removeLogin(string) {
      let loginStr = this.$store.getters.localStore("pennyLogins", string);
      let [unProto] = this.$store.getters["util/unscramble"](
        this.$store.getters["util/unreplace"](loginStr)
      );
      let un = this.$store.getters.formatUUID(unProto);
      localStorage.removeItem(un);

      this.$store.getters.localStore("pennyLogins", string, null, true);
      this.forceUpdate++;
    },
    handleScan(data) {
      // format of the data should be https://(test.)pfyt.xyz/u/BUNCHA STUFF HERE
      try {
        if (/:\/\/(test\.)?pfyt/.test(data.toLowerCase().split(".xyz")[0])) {
          let splitData = data.split(/\.xyz\/u\//i);
          splitData[0] = "";
          let endData = splitData.join("/u/");
          this.$router.push(endData);
        } else {
          throw "not the right url";
        }
      } catch (err) {
        // should snackbar here
        this.showSnackbar = true;
      }
    },
  },
  mounted() {
    let keys = Object.keys(this.savedLogins);
    if (keys.length == 1) {
      // console.log("ONLY ONE KEY SHOULD AUTO-LOG-IN BUT NOT GUNNA FOR NOW");
      // we're going to login to that one directly
      // this.$router.push(`/u/${keys[0]}`);
    }
  },
  components: {
    qrScanner,
  },
};
</script>
