<template>
  <div>
    <v-card class="pa-3">
      <h2>About</h2>
      <br />
      <h4>What is <i>Penny For Your Thoughts</i>?</h4>
      <i>Penny For Your Thoughts</i> is, at its most simple, a good romantic
      gift. The 10-second rundown (if you read fast enough): You write a
      message, then this website will help you split it into pieces. Each piece
      has a QR code generated, which you print out on sticky labels and attach
      to pennies. You then give a bunch of pennies with the QR codes stuck on
      them as a gift, and the receiver scans the QR codes to uncover the message
      over time.<br /><br />Of course, there's more to it - you can control how
      it looks, how to divide the message, how frequently the receipient can
      scan the QR codes, etc. <br /><br />
      <h4>How did it start?</h4>
      I designed this originally to give my wife a unique gift. I wrote about
      1500 words (about 2 pages single-spaced) in a letter, then generated all
      the QR codes, printed them, attached them to pennies, and filled up a
      pickle jar. Each day she was supposed to take one penny out, scan it,
      record the information, and put it in a "completed" jar. It would take 3-4
      years, but then my whole letter would be uncovered and she could read it.
      <br /><br />
      In practice, my original implementation was a little... problematic. She
      had to carefully track what she had scanned and monitor things with a
      spreadsheet to make sure everything was in order, so it ended up being a
      chore. I put some more effort in and turned it into this website to do all
      the tracking automatically - then decided to take it a step further to
      make it accessible for anybody.
      <br /><br />
      <h4>How can I do it?</h4>
      If you're interested, go ahead and click on the link below to make your
      own!
      <br /><br />
      <v-btn to="/getStarted" color="info">Create Message!</v-btn>
      <br /><br />
      <small
        >P.S. I know that this wall of text is rather lackluster. If somebody
        wants to make cool graphics or a nice video explaining it, please
        contact me - I'd love to make this page better and approachable, but -
        while I have a good amount of technical chops - I have basically 0
        artistic ability myself, so it's just a wall of text for now.</small
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {};
  },
  computed: {
    faqs() {
      return [
        {
          title: "What is 'Penny For Your Thoughts'?",
          text: "To be honest, that's the kind of question that's better answered by the 'About' page, but the long and short of it: Somebody writes a message, like a love letter or something.  This program splits it up into pieces and generates a code for each piece.  The codes are scanned (usually one each day or something like that) to uncover the message over time.",
        },
        {
          title: "How much does it cost?",
          text: "The cost is driven by two things: 1) How many QR codes are generated, and 2) Whether or not you're printing the QR codes yourself.  Each QR costs $0.01 USD (with a minimum of $5.00), and if you want the QR codes printed/mailed to you, it's an additional $0.01 USD for each QR with a minimum of $1.00 - plus shipping, which is $1.50 domestic (US), and $3.00 international.",
        },
        {
          title: "How do I use it?",
          text: "If you choose the 'Create Message' option on the home page, it'll walk you through the steps: You'll write your message, choose how to split it into pieces, choose some settings for how it's used, enter your email address, and pay.  You can have the QR codes printed and mailed to you, or you can get a PDF and print them yourself.  You'll also get a 'login' QR code: this will allow the person receiving the gift to log in and begin scanning the QR codes that will uncover the message.  Stick the labels to a bunch of pennies, and give them to the recipient with the Login QR, and you're good to go.  You can see their progress when you login through the link that gets sent to your email.",
        },
        {
          title: "Is it secure?",
          text: "Well, depends on what you mean.  Is anybody going to have unauthorized access to your letter/message?  No.  It's quite secure in that regard, stuff is well locked-down.  If, however, somebody has your login QR (such as the person you're giving the gift to, who is supposed to have it), they could probably extract the whole message if they were smart and determined - so I can't recommend using this to slowly reveal your dying, multi-billion-dollar will to competing factions among your children or something.",
        },
        {
          title: "What if I have a question that wasn't answered here?",
          text: "<span>If that happens, please reach out for support at <a href='mailto:pfyt@gmail.com'>pfyt@gmail.com</a> and I'll get back to you as soon as I can.</span>",
        },
        // {
        //   title: "",
        //   text: "",
        // },
        // {
        //   title: "",
        //   text: "",
        // },
      ];
    },
  },
};
</script>
