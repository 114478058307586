<template>
  <div class="d-flex flex-column fill-height">
    <div>
      <v-form ref="divideForm">
        <v-select
          :items="divisionModes"
          v-model="divisionMode"
          label="How should the message be divided up?"
        />
        <v-text-field
          v-if="divisionMode == 'delimiter'"
          v-model="delimiter"
          label="What is the custom separator?"
          :rules="[(v) => !!v]"
        />
        <v-text-field
          v-if="divisionMode == 'regex'"
          v-model="regexDelim"
          label="Enter the Regex Pattern (including tags) here"
          :rules="[(v) => !!v, (v) => validRegex(v)]"
        />
        <v-checkbox
          v-if="divisionMode == 'delimiter' || divisionMode == 'regex'"
          v-model="removeDelimiter"
          style="margin-top: 0px"
          label="Should this separator be removed from the output text?"
        />
        <v-text-field
          v-if="divisionMode == 'char'"
          :label="`How many characters per division? (1 to ${sliderMax})`"
          v-model="rawCharCountString"
          :rules="[
            (v) => !!v || 'Must have a value.',
            (v) =>
              (Number(v) <= sliderMax && Number(v) >= 1) ||
              'Must be in defined range.',
            (v) => Number(v) == parseInt(v) || 'Must be a whole number.',
          ]"
        />
        <v-text-field
          v-if="divisionMode == 'divide'"
          :label="`How many pieces should the message be divided into? (1 to ${sliderMax})`"
          v-model="rawInputDivisionsString"
          :rules="[
            (v) => !!v || 'Must have a value.',
            (v) =>
              (Number(v) <= sliderMax && Number(v) >= 1) ||
              'Must be in defined range.',
            (v) => Number(v) == parseInt(v) || 'Must be a whole number.',
          ]"
        />
      </v-form>
    </div>
    <span>
      Your message will be divided into
      <strong>{{ stepRules ? divisionCount : "?" }}</strong> piece{{
        divisionCount == 1 && stepRules ? "" : "s"
      }}.
    </span>
    <v-spacer />
    <center>
      <v-btn
        @click="showParts = true"
        color="info"
        class="mb-1"
        :disabled="!stepRules"
        >Show Piece{{ divisionCount == 1 ? "" : "s" }}</v-btn
      >
    </center>
    <v-spacer v-for="i in spacerCount" :key="i" />
    <v-dialog v-model="showParts">
      <v-card class="pa-3">
        <!-- <center>
          <h3>
            Parts Are Outlined in
            <span :style="`background-color: ${color1}`">Alternating</span
            >&nbsp;<span :style="`background-color: ${color2}`">Colors</span>
          </h3>
        </center>
        <br /> -->
        <span
          v-for="(item, index) of letterOutput"
          :key="index"
          :style="`background-color: ${
            index % 2 ? color1 : color2
          }; border-radius: 2px;`"
          v-html="item"
        />
        <v-card-actions>
          <v-spacer /><v-btn @click="showParts = false" color="info"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <div>
      Some examples of pieces are provided below:
      <ul>
        <li v-for="(item, index) in randomSample" :key="index">
          {{ item }}
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "DivideStep",
  props: ["letter", "runCalcs"],
  data() {
    return {
      color1: "#CCDDFF",
      color2: "#94D6C7",
      timeoutLen: 100,
      rawInputDivisionsString: "1",
      inputDivisions: 1,
      inputDivisionsTimeout: null,
      rawCharCountString: "1",
      charCount: 1,
      charCountTimeout: null,
      delimiter: "/",
      regexDelim: "/\\n/g",
      removeDelimiter: false,
      showParts: false,
      divisionMode: "word",
      divisionModes: [
        {
          text: "Each Word",
          value: "word",
        },
        {
          text: "Number of Letters",
          value: "char",
        },
        {
          text: "Number of Pieces",
          value: "divide",
        },
        {
          text: "Custom Separator",
          value: "delimiter",
        },
        {
          text: "Regex Separator (Advanced)",
          value: "regex",
        },
      ],
    };
  },
  computed: {
    stepRules() {
      if (!this.runCalcs) {
        return true;
      }
      let rules = [];
      switch (this.divisionMode) {
        case "word":
          // don't need any rules here, I don't think.
          break;
        case "char":
          rules.push(!!this.rawCharCountString);
          rules.push(
            Number(this.rawCharCountString) <= this.sliderMax &&
              Number(this.rawCharCountString) >= 1 &&
              Number(this.rawCharCountString) ==
                parseInt(this.rawCharCountString)
          );
          break;
        case "divide":
          rules.push(!!this.rawInputDivisionsString);
          rules.push(
            Number(this.rawInputDivisionsString) <= this.sliderMax &&
              Number(this.rawInputDivisionsString) >= 1 &&
              Number(this.rawInputDivisionsString) ==
                parseInt(this.rawInputDivisionsString)
          );
          break;
        case "delimiter":
          rules.push(!!this.delimiter);
          break;
        case "regex":
          rules.push(!!this.regexDelim);
          rules.push(this.validRegex(this.regexDelim) === true);
          break;
      }
      let rulesGood = true;
      for (let rule of rules) {
        rulesGood = rulesGood && rule;
        if (!rulesGood) {
          break;
        }
      }
      return rulesGood;
    },
    spacerCount() {
      return Math.max(
        Math.ceil((this.$store.getters.windowSize.height - 700) / 100),
        0
      );
    },
    letterCharCount() {
      return this.letter.length;
    },
    sliderMax() {
      if (!this.runCalcs) {
        return 1;
      }
      return this.letter.length;
    },
    dividedLetter() {
      if (!this.runCalcs) {
        return [""];
      }
      let returnVal = [];
      switch (this.divisionMode) {
        case "word": {
          returnVal = this.letter.split(/(\s+)/gi).filter((a) => a != "");
          for (let i = returnVal.length - 1; i >= 0; i--) {
            if (
              i - 1 >= 0 &&
              !returnVal[i].split(/\s+/gi).filter((a) => a != "").length
            ) {
              returnVal[i - 1] += returnVal[i];
              returnVal.splice(i, 1);
            }
          }
          break;
        }
        case "delimiter": {
          returnVal = this.letter.split(this.delimiter);
          if (!this.removeDelimiter) {
            for (let i = 0; i < returnVal.length - 1; i++) {
              returnVal[i] += this.delimiter;
            }
          }
          returnVal = returnVal.filter((a) => a != "");
          break;
        }
        case "regex": {
          let regex = this.makeRegex(this.regexDelim);
          if (typeof regex == "string") {
            returnVal = [this.letter];
            break;
          }

          let matches;
          if (regex.flags.includes("g")) {
            matches = [...this.letter.matchAll(regex)];
          } else {
            matches = [this.letter.match(regex)];
          }
          matches = matches.filter((a) => !!a);
          let output = [];
          let lastInd = 0;
          for (let i = 0; i < matches.length; i++) {
            let match = matches[i];
            let toIndex = match.index;
            let lastPart = toIndex;
            lastPart += ("" + match).length;
            if (!this.removeDelimiter) {
              toIndex = lastPart;
            }
            let subStr = this.letter.substring(lastInd, toIndex);
            lastInd = lastPart;
            output.push(subStr);
          }
          if (lastInd < this.letter.length) {
            output.push(this.letter.substring(lastInd));
          }
          returnVal = output.filter((a) => a != "");
          break;
        }
        case "char": {
          let temp = this.letter.split("");
          let lastIndex = 0;
          returnVal = temp
            .reduce(
              (acc, cur) => {
                if (acc[lastIndex].length == this.charCount) {
                  acc.push(cur);
                  lastIndex++;
                } else {
                  acc[lastIndex] += cur;
                }
                return acc;
              },
              [""]
            )
            .filter((a) => a != "");
          break;
        }
        case "divide": {
          let upperNum = Math.ceil(this.letter.length / this.inputDivisions);
          let nextDown = upperNum - 1;
          let howMany = 0;
          while (
            (this.letter.length - upperNum * howMany) / nextDown + howMany >
            this.inputDivisions
          ) {
            howMany++;
          }
          let firstPart = this.letter.substring(0, howMany * upperNum);
          let temp = firstPart.split("");
          let lastIndex = 0;
          returnVal = temp
            .reduce(
              (acc, cur) => {
                if (acc[lastIndex].length >= upperNum) {
                  acc.push(cur);
                  lastIndex++;
                } else {
                  acc[lastIndex] += cur;
                }
                return acc;
              },
              [""]
            )
            .filter((a) => a != "");
          let secondPart = this.letter.substring(howMany * upperNum);
          temp = secondPart.split("");
          returnVal = temp
            .reduce((acc, cur) => {
              if (acc[lastIndex].length >= nextDown) {
                acc.push(cur);
                lastIndex++;
              } else {
                acc[lastIndex] += cur;
              }
              return acc;
            }, returnVal)
            .filter((a) => a != "");
          break;
        }
      }
      this.$emit("update:dividedLetter", returnVal);
      return returnVal;
    },
    divisionCount() {
      return this.dividedLetter.length;
    },
    letterOutput() {
      if (!this.runCalcs || !this.showParts) {
        return [];
      }
      let output = [];
      for (let item of this.dividedLetter) {
        let textNode = document.createTextNode(item);
        let p = document.createElement("p");
        p.appendChild(textNode);
        output.push(p.innerHTML.replace(/\n/gi, "↵<br />\n"));
      }
      return output;
    },
    rawCharCount() {
      return Number(this.rawCharCountString);
    },
    rawInputDivisions() {
      return Number(this.rawInputDivisionsString);
    },
  },
  methods: {
    makeRegex(str) {
      try {
        if (str[0] == "/") {
          let lastIndex = str.lastIndexOf("/");
          if (lastIndex == 0) {
            return "Invalid RegExp";
          }
          let regFirst = str.substring(1, lastIndex);
          let tags = str.substring(2 + regFirst.length);
          return new RegExp(regFirst, tags);
        } else {
          return new RegExp(str, "");
        }
      } catch (err) {
        return "" + err;
      }
    },
    validRegex(str) {
      let regex = this.makeRegex(str);
      if (typeof regex == "object") {
        return true;
      } else {
        return regex;
      }
    },
  },
  mounted() {},
  watch: {
    rawCharCount() {
      let self = this;
      if (self.charCountTimeout != null) {
        clearTimeout(self.charCountTimeout);
      }
      self.charCountTimeout = setTimeout(() => {
        self.charCount = self.rawCharCount;
        self.charCountTimeout = null;
      }, self.timeoutLen);
    },
    rawInputDivisions() {
      let self = this;
      if (self.inputDivisionsTimeout != null) {
        clearTimeout(self.inputDivisionsTimeout);
      }
      self.inputDivisionsTimeout = setTimeout(() => {
        if (
          self.rawInputDivisions <= self.letterCharCount &&
          self.rawInputDivisions >= 1
        ) {
          self.inputDivisions = self.rawInputDivisions;
        }
        self.inputDivisionsTimeout = null;
      }, self.timeoutLen);
    },
    stepRules(newVal) {
      this.$emit("update:divisionGood", newVal);
    },
    divisionMode() {
      let self = this;
      self.$nextTick(() => {
        self.$refs.divideForm.validate();
      });
    },
  },
};
</script>
