<template>
  <div class="d-flex flex-column align-stretch">
    <v-card
      color="white"
      elevation="5"
      rounded="lg"
      style="
        cursor: pointer;
        border-width: 0.3vmax;
        border-style: solid;
        border-color: #aaa !important;
      "
      class="d-flex flex-column flex-grow-1"
    >
      <v-stepper
        v-model="instructionSteps"
        v-if="!(userData instanceof Boolean) && typeof userData != 'boolean'"
        height="100%"
        class="
          align-self-stretch
          flex-grow-1
          d-flex
          flex-column
          pa-3
          custom-stepper
        "
        :class="{ 'custom-stepper-alt': $vuetify.breakpoint.smAndDown }"
        alt-labels
      >
        <center class="d-flex align-center justify-center">
          <v-btn
            :style="`opacity: ${instructionSteps > 1 ? '1' : '0'};`"
            :disabled="instructionSteps <= 1"
            @click="instructionSteps--"
            fab
            small
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <h3 style="display: inline">
            {{ stepText }}
          </h3>
          <v-spacer />
          <v-btn
            :style="`opacity: ${instructionSteps >= steps.length ? '0' : '1'};`"
            :disabled="instructionSteps >= steps.length"
            @click="instructionSteps++"
            fab
            small
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </center>

        <v-stepper-content
          :step="steps.indexOf('Print Status') + 1"
          class="pa-3"
        >
          <div>
            When you signed up for <i>Penny For Your Thoughts</i>, you requested
            the labels be printed and mailed to you.<br /><br />
            <b
              ><span class="error--text" v-if="!sentLabels"
                >Your labels have not yet been mailed.</span
              ><span class="success--text" v-else
                >Your labels have been mailed.</span
              ></b
            >
            <br /><br />
            If labels have not arrived after two weeks, please contact support.
          </div>
        </v-stepper-content>

        <v-stepper-content
          :step="steps.indexOf('Label Paper') + 1"
          class="pa-3"
        >
          <div>
            The templates for your labels are designed to be printed onto
            <a :href="$store.getters.labelLink">this label paper</a>. (Affiliate
            link)<br /><br />
            You can print the QR codes on normal paper and glue/tape to pennies,
            but adhesive labels are recommended.
          </div>
        </v-stepper-content>

        <v-stepper-content
          :step="steps.indexOf('Adjust Margin') + 1"
          class="pa-3"
        >
          <div>
            To compensate for printer error, we will calibrate the margin.<br /><br />
            Please adjust the "Y-Margin" field below - it will adjust the
            distance from the top of the page to the beginning of the labels.<br /><br />
            Try printing the "Label Template" on normal paper and compare the
            alignment to the adhesive label paper. Adjust until they match.<br /><br />
            <v-text-field
              v-model="yMargin"
              label="Y Margin (Inches, negative values supported)"
              :rules="[
                (v) =>
                  (Number(v) <= 11 && Number(v) >= -11) ||
                  'Number must be between -11 and 11 inches.',
              ]"
            /><br />
            <v-btn @click="blankTemplate" color="grey lighten-2" class="mr-1"
              >Label Template</v-btn
            ><br />
            <small
              >You may need to allow pop-up windows for the label template
              button to work.</small
            >
          </div>
        </v-stepper-content>

        <v-stepper-content
          :step="steps.indexOf('Settings (1/2)') + 1"
          class="pa-3"
        >
          <div>
            <v-checkbox
              v-model="onlyPrintUnscanned"
              label="Only Print Unscanned QR Codes?"
              class="ml-3"
            />
            <small
              >So far, <b>{{ uncovered.dates.length }}</b> QR codes have been
              scanned. If you check above, only the QR codes that have
              <i>not</i> been scanned will be printed.</small
            >
          </div>
        </v-stepper-content>

        <v-stepper-content
          :step="steps.indexOf('Settings (2/2)') + 1"
          class="pa-3"
        >
          <div>
            <v-checkbox
              v-model="randomizeOrder"
              label="Randomize QR Order?"
              class="ml-3"
            />
            <small
              >When printing, the QR codes will normally be in order - the first
              part of the message, then the second, etc. They will likely be
              randomized in the process of attaching them to pennies, but
              checking the box above adds another layer of randomization.</small
            >
          </div>
        </v-stepper-content>

        <v-stepper-content :step="steps.indexOf('Print') + 1" class="pa-3">
          <div>
            There are two important things to print:
            <ol>
              <li>The QR Codes labels to be placed on the pennies</li>
              <li>The "Login" QR Code to access the message</li>
            </ol>
            <br />
            <v-btn
              @click="qrCodes"
              color="grey lighten-2"
              class="mr-1"
              :disabled="!letterDataLength"
              ><v-progress-circular v-if="!letterDataLength" indeterminate />QR
              Codes</v-btn
            ><br /><br />
            <v-btn @click="loginQR" color="grey lighten-2" class="mb-3"
              >Login QR</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content :step="steps.indexOf('Attach') + 1" class="pa-3">
          <div>
            Once the labels are printed, attach each label to a penny. Rolls of
            pennies (typically 50 per roll) can be obtained at most banks
            without an exchanging charge.
          </div>
        </v-stepper-content>

        <v-stepper-content :step="steps.indexOf('Give') + 1" class="pa-3">
          <div>
            Once all QR labels are attached, it is recommended to put the
            pennies in a container like a jar. Attach the "Login QR" code to the
            jar as well, so the gift recipient can access their message and
            begin scanning pennies.<br /><br />
            Thanks for using <i>Penny For Your Thoughts</i>! You can track the
            scanning progress by opening the "Stats" tile.
          </div>
        </v-stepper-content>

        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :key="index"
              :step="index + 1"
              :complete="instructionSteps > index + 1"
              @click="goToStep(index)"
            >
              {{ step }}
            </v-stepper-step>
            <v-divider
              v-if="index < steps.length - 1"
              :key="`${index}divider`"
              :style="`flex-basis: ${
                index == longDivide ? '100%' : '0'
              } !important; margin-left: ${divideMargin}; margin-right: ${divideMargin}; margin-top: ${
                index == longDivide ? '0px; opacity: 0;' : '21px;'
              }`"
            />
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-card>
  </div>
</template>

<style>
.custom-stepper {
  height: auto !important;
}

.custom-stepper .v-stepper__header {
  box-shadow: none !important;
}

.custom-stepper .v-stepper__header .v-stepper__step {
  flex-basis: 0 !important;
  flex-grow: 1;
  padding: 10px !important;
}

.custom-stepper .v-stepper__header .v-stepper__step .v-stepper__label {
  text-align: center;
}

.custom-stepper-alt .v-stepper__header .v-stepper__step__step {
  margin-bottom: 0px !important;
}

.custom-stepper .v-stepper__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.custom-stepper .v-stepper__content .v-stepper__wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.custom-stepper .v-stepper__content > .v-stepper__wrapper > * {
  flex-grow: 1;
}
</style>

<script>
const QRCode = require("qrcode");

export default {
  name: "PrintLabels",
  data() {
    return {
      instructionSteps: 1,
      showSnackbar: false,
      yMarginGood: 0.0,
      yMarginRaw: "0.0",
      defaultSteps: [
        "Label Paper",
        "Adjust Margin",
        "Settings (1/2)",
        "Settings (2/2)",
        "Print",
        "Attach",
        "Give",
      ],
      onlyPrintUnscanned: true,
      randomizeOrder: false,
    };
  },
  computed: {
    yMarginPrint() {
      return this.yMarginGood + 0.125;
    },
    yMargin: {
      get() {
        return this.yMarginRaw;
      },
      set(newVal) {
        this.yMarginRaw = newVal;
        let yMargin = Number(newVal);
        if (yMargin < 9999 && yMargin > -9999) {
          this.yMarginGood = yMargin;
        }
      },
    },
    baseUrl() {
      return this.$store.getters.baseUrl;
    },
    userData() {
      let ud = this.$store.getters["awsRegistered/userData"];
      if (ud === false) {
        this.$store.dispatch("awsRegistered/getUserData");
        this.$store.commit("awsRegistered/updateGettingUserData", true);
        return true;
      } else {
        return ud;
      }
    },
    requestPrint() {
      let ud = this.userData;
      if (ud !== true) {
        return ud.printLabels;
      }
      return false;
    },
    sentLabels() {
      return this.requestPrint === "done";
    },
    uncovered() {
      let data = this.$store.getters["awsRegistered/uncovered"];
      if (!data.loaded) {
        this.$store.commit("awsRegistered/loadingUncovered");
        this.$store.dispatch("awsRegistered/getUncovered", { need: true });
      }
      return {
        byIndex: data.byIndex,
        dates: data.dates,
      };
    },
    letterDataLength() {
      if (this.$store.getters["awsRegistered/letterLength"] < 0) {
        if (this.$store.getters["awsRegistered/letterLength"] == -2) {
          this.$store.commit("awsRegistered/updateLetterLength", -1);
          this.$store.dispatch("awsRegistered/getLetter");
        }
        return 0;
      } else {
        return this.$store.getters["awsRegistered/letterLength"];
      }
    },
    letterParts() {
      let output = [];
      let filter = this.onlyPrintUnscanned
        ? Object.keys(this.uncovered.byIndex)
        : [];
      for (let i = 1; i <= this.letterDataLength; i++) {
        if (!filter.includes(`${i}`)) {
          output.push(i);
        }
      }
      if (this.randomizeOrder) {
        this.$store.getters.shuffleArray(output);
      }
      return output;
    },
    steps() {
      let output = [];
      if (this.requestPrint) {
        output.push("Print Status");
      }
      for (let step of this.defaultSteps) {
        output.push(step);
      }
      return output;
    },
    stepText() {
      return `Step ${this.instructionSteps}: ${
        this.steps[this.instructionSteps - 1]
      }`;
    },
    longDivide() {
      if (this.$store.getters.windowSize.width <= 490) {
        return 3;
      } else {
        return -1;
      }
    },
    divideMargin() {
      // with like 2 items we would want like -15 px
      // with 6 we want like -7
      // But it also depends on the breakpoint.
      return `calc(((100vw - 54px - 42px * ${this.steps.length}) / ${this.steps.length}) / (0 - 2))`;
    },
  },
  methods: {
    async goToStep(index) {
      index++;
      if (index < this.instructionSteps || index < this.steps.length) {
        this.instructionSteps = index;
      }
    },
    async blankTemplate() {
      let url = await this.$store.dispatch("templateGenerator/makePDF", {
        yFudge: this.yMarginPrint,
      });
      window.open(url, "_blank");
    },
    async qrCodes() {
      let url = await this.$store.dispatch("templateGenerator/makePDF", {
        letterParts: this.letterParts,
        template: false,
        yFudge: this.yMarginPrint,
      });
      window.open(url, "_blank");
    },
    async loginQR() {
      let self = this;
      let info = await new Promise((resolve, reject) => {
        QRCode.toDataURL(
          `${this.baseUrl}/u/` +
            encodeURIComponent(
              self.$store.getters["util/replace"](self.$route.params.login)
            ),
          {
            type: "png",
            errorCorrectionLevel: "H",
            margin: 4,
          },
          (err, string) => {
            if (err) {
              reject(err);
            } else {
              resolve(string);
            }
          }
        );
      });

      let byteCharacters = atob(info.split(",")[1]);
      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        let slice = byteCharacters.slice(offset, offset + 1024);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      let blob = new Blob(byteArrays, { type: "image/png" });
      let blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl, "_blank");
    },
  },
  watch: {
    userData() {
      // just need to be sure the value is refreshed
    },
  },
};
</script>
