<template>
  <div>
    <v-checkbox
      v-model="requestPrintModel"
      label="Do you want to have the QR codes printed and shipped to you?"
      class="ml-3"
    />
    <small
      >This website can generate PDFs for printing the QR codes onto labels
      (purchaseable from
      <a :href="$store.getters.labelLink" target="_blank">Amazon</a>).
      Alternatively, for $0.01/QR extra + shipping, they will be automatically
      printed and mailed to you.</small
    ><br />
    <br v-if="printLabels" />
    <v-alert type="warning" v-if="shippingWarning" prominent>
      Shipping may take up to two weeks, which is after your requested start
      date.
    </v-alert>
    <!-- <v-checkbox
      v-model="internationalShipping"
      v-if="requestPrint"
      label="Is your shipping address international (outside the US)?"
      class="ml-3"
    /> -->
    <small v-if="printLabels"
      ><strong
        >Labels will be shipped to the address associated with your
        payment.</strong
      >
      Shipping within the US is $1.50, and outside the US is $3.00.</small
    >
  </div>
</template>

<script>
export default {
  name: "PrintLabelsStep",
  props: ["printLabels", "startDate"],
  computed: {
    requestPrintModel: {
      get() {
        return this.printLabels;
      },
      set(newVal) {
        this.$emit("update:printLabels", newVal);
      },
    },
    shippingWarning() {
      if (this.printLabels) {
        let d = new Date();
        let twoWeekDate = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate() + 14
        );
        return twoWeekDate.getTime() > this.startDate.getTime();
      } else {
        return false;
      }
    },
  },
};
</script>
