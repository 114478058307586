var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column fill-height"},[_c('div',[_c('v-form',{ref:"divideForm"},[_c('v-select',{attrs:{"items":_vm.divisionModes,"label":"How should the message be divided up?"},model:{value:(_vm.divisionMode),callback:function ($$v) {_vm.divisionMode=$$v},expression:"divisionMode"}}),(_vm.divisionMode == 'delimiter')?_c('v-text-field',{attrs:{"label":"What is the custom separator?","rules":[function (v) { return !!v; }]},model:{value:(_vm.delimiter),callback:function ($$v) {_vm.delimiter=$$v},expression:"delimiter"}}):_vm._e(),(_vm.divisionMode == 'regex')?_c('v-text-field',{attrs:{"label":"Enter the Regex Pattern (including tags) here","rules":[function (v) { return !!v; }, function (v) { return _vm.validRegex(v); }]},model:{value:(_vm.regexDelim),callback:function ($$v) {_vm.regexDelim=$$v},expression:"regexDelim"}}):_vm._e(),(_vm.divisionMode == 'delimiter' || _vm.divisionMode == 'regex')?_c('v-checkbox',{staticStyle:{"margin-top":"0px"},attrs:{"label":"Should this separator be removed from the output text?"},model:{value:(_vm.removeDelimiter),callback:function ($$v) {_vm.removeDelimiter=$$v},expression:"removeDelimiter"}}):_vm._e(),(_vm.divisionMode == 'char')?_c('v-text-field',{attrs:{"label":("How many characters per division? (1 to " + _vm.sliderMax + ")"),"rules":[
          function (v) { return !!v || 'Must have a value.'; },
          function (v) { return (Number(v) <= _vm.sliderMax && Number(v) >= 1) ||
            'Must be in defined range.'; },
          function (v) { return Number(v) == parseInt(v) || 'Must be a whole number.'; } ]},model:{value:(_vm.rawCharCountString),callback:function ($$v) {_vm.rawCharCountString=$$v},expression:"rawCharCountString"}}):_vm._e(),(_vm.divisionMode == 'divide')?_c('v-text-field',{attrs:{"label":("How many pieces should the message be divided into? (1 to " + _vm.sliderMax + ")"),"rules":[
          function (v) { return !!v || 'Must have a value.'; },
          function (v) { return (Number(v) <= _vm.sliderMax && Number(v) >= 1) ||
            'Must be in defined range.'; },
          function (v) { return Number(v) == parseInt(v) || 'Must be a whole number.'; } ]},model:{value:(_vm.rawInputDivisionsString),callback:function ($$v) {_vm.rawInputDivisionsString=$$v},expression:"rawInputDivisionsString"}}):_vm._e()],1)],1),_c('span',[_vm._v(" Your message will be divided into "),_c('strong',[_vm._v(_vm._s(_vm.stepRules ? _vm.divisionCount : "?"))]),_vm._v(" piece"+_vm._s(_vm.divisionCount == 1 && _vm.stepRules ? "" : "s")+". ")]),_c('v-spacer'),_c('center',[_c('v-btn',{staticClass:"mb-1",attrs:{"color":"info","disabled":!_vm.stepRules},on:{"click":function($event){_vm.showParts = true}}},[_vm._v("Show Piece"+_vm._s(_vm.divisionCount == 1 ? "" : "s"))])],1),_vm._l((_vm.spacerCount),function(i){return _c('v-spacer',{key:i})}),_c('v-dialog',{model:{value:(_vm.showParts),callback:function ($$v) {_vm.showParts=$$v},expression:"showParts"}},[_c('v-card',{staticClass:"pa-3"},[_vm._l((_vm.letterOutput),function(item,index){return _c('span',{key:index,style:(("background-color: " + (index % 2 ? _vm.color1 : _vm.color2) + "; border-radius: 2px;")),domProps:{"innerHTML":_vm._s(item)}})}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.showParts = false}}},[_vm._v("Close")])],1)],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }