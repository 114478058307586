<template>
  <div>
    {{ displayData }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBit: "",
      displayData: "Loading...",
    };
  },
  computed: {
    uncovered() {
      console.log("uncovered loaded initially");
      let data = this.$store.getters["awsRegistered/uncovered"];
      console.log("value is: ", JSON.parse(JSON.stringify(data)));
      if (!data.loaded) {
        console.log("dispatching");
        this.$store.commit("awsRegistered/loadingUncovered");
        this.$store.dispatch("awsRegistered/getUncovered", { need: true });
      }
      return {
        loaded: data.loaded,
        byIndex: data.byIndex,
        dates: data.dates,
      };
    },
  },
  methods: {
    async getUncoveredLetter() {
      let letterData = JSON.parse(
        JSON.stringify(await this.$store.dispatch("awsRegistered/getLetter"))
      );
      let indices = Object.keys(this.uncovered.byIndex);
      for (let i = 0; i < indices.length; i++) {
        indices[i] = indices[i] - 1;
      }
      for (let i = 0; i < letterData.length; i++) {
        if (!indices.includes(i)) {
          letterData[i] = letterData[i].replace(/./gi, "_");
        }
      }
      return letterData.join("");
    },
  },
  watch: {
    uncovered: {
      async handler(newVal) {
        if (newVal.loaded === true) {
          this.displayData = await this.getUncoveredLetter();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.uncovered.loaded === true) {
      this.displayData = await this.getUncoveredLetter();
    }
  },
};
</script>
