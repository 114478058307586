<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event.target.value)"
    max-width="90vw"
    width="unset"
    persistent
  >
    <div style="position: relative; height: 100%; max-width: 90vw">
      <video
        ref="videoHolder"
        :style="`
          display: block;
          max-width: 90vw;
          max-height: 90vh;
          width: ${videoWidth};
          height: ${videoHeight};
        `"
      />
      <v-btn
        @click="$emit('input', false)"
        color="error"
        style="position: absolute; bottom: 20px; right: 20px; z-index: 999"
      >
        Close
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!@/../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default {
  name: "qrScan",
  props: ["value", "timeoutLen"],
  data() {
    return {
      qrScannerInstance: null,
      justRead: null,
      readTime: 0,
      timeoutDefault: 3000,
      cameraSize: {
        width: 1,
        height: 1,
      },
    };
  },
  computed: {
    timeout() {
      if (this.timeoutLen) {
        return this.timeoutLen;
      } else {
        return this.timeoutDefault;
      }
    },
    windowSize() {
      return this.$store.getters.windowSize;
    },
    cameraScale() {
      let widthScale = this.cameraSize.width / (this.windowSize.width * 0.9);
      let heightScale = this.cameraSize.height / (this.windowSize.height * 0.9);
      if (widthScale > heightScale) {
        return 1 / widthScale;
      } else {
        return 1 / heightScale;
      }
    },
    videoWidth() {
      return this.cameraSize.width * this.cameraScale + "px";
    },
    videoHeight() {
      return this.cameraSize.height * this.cameraScale + "px";
    },
  },
  methods: {
    async handleQrRead(data) {
      if (data == "") {
        return;
      }
      let readAt = new Date().getTime();
      if (
        this.justRead == data &&
        readAt - this.readTime < this.timeout &&
        this.readTime != 0
      ) {
        return;
      } else {
        this.justRead = data;
        this.readTime = readAt;
      }
      this.$emit("scan", data);
    },
  },
  async mounted() {
    let self = this;
    self.qrScannerInstance = new QrScanner(
      self.$refs.videoHolder,
      self.handleQrRead
    );
    this.$refs.videoHolder.addEventListener("loadedmetadata", () => {
      let settings = self.$refs.videoHolder.srcObject
        .getTracks()[0]
        .getSettings();
      self.cameraSize.width = settings.width;
      self.cameraSize.height = settings.height;
    });
    self.qrScannerInstance.start();
  },
  destroyed() {
    if (this.qrScannerInstance != null) {
      this.qrScannerInstance.stop();
      this.qrScannerInstance.destroy();
      this.qrScannerInstance = null;
    }
  },
};
</script>
